body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(243, 240, 240);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

i {
  color: white;
}



#glass {
  margin-top: 2rem;
  margin-bottom: 1rem;
  padding: 1.2rem;
  border-radius: 10px;
  box-shadow: 0 0 12px rgb(156, 154, 154);
  background-color: white;
}

#header-glass {
  margin-top: 2rem;
  margin-bottom: 1rem;
  padding: 1.2rem;
  border-radius: 10px;
  box-shadow: 0 0 12px rgb(156, 154, 154);
  background-image: url('./img3.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
}

#nav-r {
  background-color: rgb(10, 19, 29);
  box-shadow: 0 0 12px rgb(156, 154, 154);
  margin-top: 2rem;
  border-radius: 12px;
  padding: 0.7rem;
}


.nav-link {
  color: white;
}

#header-r {
  margin-bottom: 3.5rem;
}

p {
  text-align: justify;
}

.navbar-toggler {
  box-shadow: none !important;
}

#row {
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
}

#products {
  background-color: rgb(10, 19, 29);
  color: white;
  border-radius: 10px;
  padding: 1rem;
}

#wa-icon {
  color: rgb(30, 175, 30);
  font-size: 50px;
}

#e-icon {
  color: rgb(233, 225, 231);
  font-size: 38px;
}


.logo {
  height: 200px;
  width: 200px;
  border-radius: 100%;
  margin-bottom: 1.2rem;
}

.card {
  background-color: transparent;
  padding: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.card img {
  width: 90%;
  height: 230px;
  border-radius: 10px;
}

#frow .col-sm-3 {
  text-align: center;
}

#frow .col-sm-4 {
  text-align: center;
}

#glass-col {
  background-color: white;
  border-radius: 10px;
  padding: 0.8rem;
  color: black;
  margin-left: 1.2rem;
  margin-right: 1.2rem;
}

#frow .col-sm-3 p {
  text-align: justify;
}

#frow .col-sm-4 p {
  text-align: justify;
}

.alert {
  padding: 1rem;
}


.form-control {
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
}

#contact {
  color: white;
  box-shadow: none !important;
}

.col-sm-4 {
  align-items: center;
}

.btn { 
  color: white;
}

.btn-root {
  background-color: rgb(57, 212, 57);
}

#featureicon {
  font-size: 56px;
  color: rgb(10, 19, 29);
}

@media screen and (max-width: 768px) {
  body {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    background-size:auto;
    background-repeat: no-repeat;
  }

  .col-sm-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  #glass-col {
    margin-left: 0;
    margin-right: 0.4rem;
  }

  #glass-col p {
    font-size: 14px;
  }

  .logo {
    height: 120px;
    width: 120px;
  }

  #featureicon {
    font-size: 32px;
  }

  #wa-icon, #e-icon {
    font-size: 32px;
  }
}

